import React from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";
import { defaultLanguage } from "../../i18n";
import NoImage from "../media/NoImage";

import type { PostItemProps } from "./Posts.types";

const PostItem = ({
  postContent,
  category,
}: PostItemProps): React.JSX.Element => {
  const intl = useIntl();
  const locale =
    defaultLanguage === intl.locale
      ? `${process.env.GATSBY_DEFAULT_LANG}`
      : `${intl.locale}`;

  // prettier-ignore
  const thumbExists = postContent.featuredImage && postContent.featuredImage.node;
  let thumbnail = null;
  let alt = process.env.GATSBY_SITE_NAME;

  // prettier-ignore
  if (thumbExists) {
    const { slug, mediaDetails, mediaItemUrl } = postContent.featuredImage?.node ?? {};
    const filteredSizesExist = mediaDetails && mediaDetails.filteredSizes && mediaDetails.filteredSizes.length > 0;
    alt = slug ?? alt;
  
    if (filteredSizesExist) {
      const { filteredSizes } = mediaDetails;
      const getThumbnail = filteredSizes?.find(img => img.name === 'td_741x486');
      thumbnail = (getThumbnail?.sourceUrl) ? getThumbnail.sourceUrl : filteredSizes?.[0].sourceUrl;
    } else {
      thumbnail = mediaItemUrl ?? thumbnail;
    }
  }

/**
   * Long URLs switch do not overwrite!
   */
  const postLink = postContent.uri;

  const postTitle =
    postContent.title.length > 70
      ? `${postContent.title.slice(0, 70 - 1)} ...`
      : postContent.title;

  const authorFirstName = postContent.author
    ? postContent?.author?.node?.name.split(" ")[0]
    : "Unknown";

  const formattedDate = new Date(postContent?.date ?? "").toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );
  const rawExcerpt = postContent.excerpt?.replace(/(<([^>]+)>)/gi, "");

  return (
    <div className="post-item">
      <Link to={postLink} className="post-item__link">
        {thumbnail ? (
          <img
            className="post-item__image"
            loading="lazy"
            src={thumbnail}
            alt={alt}
          />
        ) : (
          <NoImage />
        )}
        <div className="post-item__text-container">
          {category && <div className="post-item__category">{category}</div>}
          <div className="post-item__title">{postTitle}</div>
          <div className="post-item__excerpt">{rawExcerpt}</div>
          <div className="post-item__meta">
            By <span className="post-item__author">{authorFirstName}</span> -{" "}
            <span className="post-item__date">{formattedDate}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PostItem;
